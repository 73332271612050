@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/0f452f23aac93ccf.p.woff2) format('woff2');
font-display: optional;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/5e104a2f5ca4b70c.p.woff2) format('woff2');
font-display: optional;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/9bc67a684cf332f6.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/c9e93908eb9d7322.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/dc9fd55bc8a553cc.p.woff2) format('woff2');
font-display: optional;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/6f75c9b7bc872d15.p.woff2) format('woff2');
font-display: optional;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/927daeb30d251608.p.woff2) format('woff2');
font-display: optional;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/52c3f4fccdd4b7d3.p.woff2) format('woff2');
font-display: optional;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/476dcb10169fc719.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__sfPro_92b530';
src: url(/_next/static/media/4fb1046f9f56048b.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: italic;
}.__className_92b530 {font-family: '__sfPro_92b530'
}.__variable_92b530 {--font-sfpro: '__sfPro_92b530'
}

